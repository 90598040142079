///////////////////////////////[AVOLOI]///////////////////////////////////////
$('.container_form_avoloi .ui.dropdown').dropdown({transition: 'drop'}).dropdown({on: 'hover'});

//Ancre vers le formulaire Avoloi

$('a.ancre_form_avoloi').on('click', function (evt) {
    evt.preventDefault();
    var target = $(this).attr('href');
    $('html, body').stop().animate({scrollTop: $(target).offset().top}, 1000);
});

// Formulaire avoloi AJAX
$('.form-avoloi').submit(function (e) {
    $(this).find("button[type='submit']").prop('disabled',true);
    $(this).find("button[type='submit']").hide();
    $(".ui.success.message").remove();
    e.preventDefault();

    $.post('addForm.php', $(this).serialize(), function (data) {
        var avoloi_response_type;

        $('#formulaire_avoloi').next('.ui.message').remove();

        if (isset(data.errors)){
            var htmlerrors = '<div class="ui error message">';
            $.each(data.errors, function (index, value) {
                htmlerrors += '<div class="header">- ' + value[0] + '</div>';
            });
            htmlerrors += '</div>';
            $("#formulaire_avoloi").after(htmlerrors);
        } else {
            if (data.status == 1) {
                avoloi_response_type = 'success';
                $('.form-avoloi')[0].reset();
            } else {
                avoloi_response_type = 'error';
            }
            $("#formulaire_avoloi").after('<div class="ui ' + avoloi_response_type + ' message"><div class="header">' + data.message + '</div></div>');
        }
        $(this).prop('disabled', false);

    }, 'json');

    $('html, body').animate({
        scrollTop: $("#formulaire_avoloi").offset().top - 50
    }, 800);

    $(this).prop('disabled', true);

    $(this).find("button[type='submit']").show();
    $(this).find("button[type='submit']").prop('disabled',false);
});
