
$(document).ready(function() {

//if close button is clicked
  $('.window .close').click(function (e) {
    //Cancel the link behavior
    e.preventDefault();

    $('#mask').hide();
    $('.window').hide();
  });

  //if mask is clicked
  $('#mask').click(function () {
    $(this).hide();
    $('.window').hide();
  });

});


function showPopup(id,params) {

    //Get the screen height and width
    var maskHeight = $(document).height();
    var maskWidth = $(window).width();

    //Set heigth and width to mask to fill up the whole screen
    $('#mask').css({'width':maskWidth,'height':maskHeight});

    //transition effect
    //$('#mask').fadeIn(1000);
    $('#mask').fadeTo("slow",0);

    //Get the window height and width
    var winH = $(window).height();
    var winW = $(window).width();

    // Parameters
    if ( typeof params === 'object' && params.width != '' ) $("#" + id).css('width', params.width);

    //Set the popup window to center
    $("#" + id).css('top',  winH/2-$("#" + id).height()/2);
    $("#" + id).css('left', winW/2-$("#" + id).width()/2);


    //transition effect
    $("#" + id).fadeIn(400);

};