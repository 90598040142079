//////////////////////////////////[LEADS]///////////////////////////////////////
if($('#count_leads').length > 0){
    

    //On sélectionne le mois en cours
    $('option:eq(1)').attr('selected', 'selected');

    //Compteur de leads
    $.ajax({
        type: 'post',
        url: 'compteur_leads.php',
        datatype: 'json',
        data: {option: $('option:eq(1)').val()},
        success: function (response) {
            $('.print-ajax').html(response);//This will print you result
        }
    });

    //Table des leads
    $.ajax({
        type: 'post',
        url: 'table_leads.php',
        datatype: 'json',
        data: {option: $('option:eq(1)').val()},
        success: function (response) {
            $('.table_leads').html(response);//This will print you result
        }
    });
}

$("#count_leads").change(function () {

    //Compteur des leads
    $.ajax({
        type: 'post',
        url: 'compteur_leads.php',
        datatype: 'json',
        data: {option: $(this).val()},
        success: function (response) {
            $('.print-ajax').html(response);//This will print you result
        }
    });

    //Table des leads
    $.ajax({
        type: 'post',
        url: 'table_leads.php',
        datatype: 'json',
        data: {option: $(this).val()},
        success: function (response) {
            $('.table_leads').html(response);//This will print you result
        }
    });

});
