var ajaxLink = function( event, thiselement ) {

  if (!Object.entries)
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
  
  event.preventDefault();
  var $this = thiselement;

  if ($this.attr('prevent')) 
  {
     MsgBoxPrevent($this.attr('prevent'),$this[0].id);
     return false;
  }

  // Converti les paramètres pour passer dans la requete AJAX
  var params = JSON.parse(  $this.attr('params').replace(/["']/g, "\"")  );

  var urlParameters = Object.entries(params).map(function (e) {
      return encodeURIComponent(e[0])+"="+encodeURIComponent(e[1]);
  }).join('&');


  $.ajax({
          url: $this.attr('href'),
          type: 'POST', 
          data: urlParameters,
          dataType : 'json',
          headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          },
          beforeSend: function(){},
          complete: function(){},
          success: function(data) { 
              
              if ( data.debug ) console.log(data);

              // Gestion des erreurs
              if ( data.success == false )
              {
                  MsgBoxKo(data.errors);

                  // CallFunction si besoin
                  if ( typeof data.call_js_function !== 'undefined' ) 
                  {
                    var fn = window[data.call_js_function];
                    if(typeof fn === 'function') {
                      fn(data.call_js_arguments);
                    }
                  }

                  return; // fin
              }
              else if ( data.success == true )
              {
                    // Redirection vers une page (si nécessaire)
                    if ( data.reload == true )
                    {
                        window.location.reload(true);
                    }
                    else if ( isset(data.redirect_url) && data.redirect_url.trim() != '' ) 
                    {
                      if ( data.msg != "" ) // Si message de confirmation existant
                      {
                          MsgBoxOk(data.msg, data.redirect_url);
                          return;
                      }
                      else // Redirection immédiate
                      {
                        if ( window.location.href.indexOf('?') > -1 )
                          var current_url = document.location.pathname + '?' + window.location.href.split('?')[1].match(/(^[^#]*)/)[0];
                        else
                          var current_url = document.location.pathname;

                        var final_url = data.redirect_url.match(/(^[^#]*)/)[0];
                        if ( current_url == final_url ) window.location.reload(true);
                        window.location = data.redirect_url;

                        return;
                      }
                    }

                    if ( data.msg != "" ) MsgBoxOk(data.msg);
                    
                    // CallFunction si besoin
                    if ( typeof data.call_js_function !== 'undefined' ) 
                    {
                      var fn = window[data.call_js_function];
                      if(typeof fn === 'function') {
                        fn(data.call_js_arguments);
                      }
                    }
              }      
          },
            error:function(x,e) {
              $(".msgboxdebug.modal #msgbox_debug_content").html(x.responseText);
              if (x.status==0) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Aucune connexion Internet'); 
                  $(".msgboxdebug.modal #msgbox_debug_content").html("Aucun accès à Internet");
              } else if(x.status==404) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Adresse URL introuvable');
                  $(".msgboxdebug.modal #msgbox_debug_content").html("Erreur 404 - PAGE INTROUVABLE");
              } else if(x.status==500) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Internel Server Error'); 
                  $(".msgboxdebug.modal #msgbox_debug_content").html("Erreur 500 - ERREUR CRITIQUE SUR LA PAGE");
              } else if(e=='parsererror') {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Parsing JSON Request failed'); 
              } else if(e=='timeout'){
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Request Time out'); 
              } else {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Erreur inconnue');
              }
              $('.msgboxdebug.modal').modal({
                      closable  : true,
                      dimmerSettings: {opacity:0}
              }).modal('show');                 
          }
  });
 
}