function ajaxView(params, datas)
{
  $.ajax({
            url: params.url,
            type: 'POST', 
            data: { params: JSON.stringify(params), datas: JSON.stringify(datas) },
            dataType : 'json',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function(data) { 
                $('#tableloader').addClass('active');
                
                // Remonte le scroll 
                if ( params.scrolltop == true && $("body, html").scrollTop() > 0 )
                {
                        $('html, body').animate({
                            scrollTop: $("#" + params.id).offset().top - 100
                        }, 1000);
                }
            },
            complete: function(){
                $('#tableloader').removeClass('active');
            },
            success: function(data) { 

                
                    if ( data.debug ) console.log(data);
                    
                    if ( data.success == false )
                    {
                       MsgBoxKo(data.errors);
                    }
                    else
                    {
                        // Ajoute le contenu HTML dans la DIV
                        $("#" + params.id).html(data.html);

                        var rowCount = $("#" + params.id +' table tbody tr').length;
                        if ( rowCount > 0 )
                        {
                            $("#" + params.id +' table').show();

                            // Active les liens href présents
                            $("#" + params.id + ' a#jslink').click(function() { ajaxLink(event, $(this)); });

                            // Active les liens du tableau présents
                            $("#" + params.id + ' tr[data-href]').on("click", function() {
                                document.location = $(this).data('href');
                            }); 

                            if ( params.activeDisabled == true ) $("#" + params.id + " .hide-when-disabled").hide();    
                        }
                        else
                        {
                            $("#" + params.id +' table').hide();
                        }
                    }
            },
            error:function(x,e) {
              $(".msgboxdebug.modal #msgbox_debug_content").html(x.responseText);
              if (x.status==0) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Aucune connexion Internet'); 
                  $(".msgboxdebug.modal #msgbox_debug_content").html("Aucun accès à Internet");
              } else if(x.status==404) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Adresse URL introuvable');
                  $(".msgboxdebug.modal #msgbox_debug_content").html("Erreur 404 - PAGE INTROUVABLE");
              } else if(x.status==500) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Internel Server Error'); 
                  $(".msgboxdebug.modal #msgbox_debug_content").html("Erreur 500 - ERREUR CRITIQUE SUR LA PAGE");
              } else if(e=='parsererror') {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Parsing JSON Request failed'); 
              } else if(e=='timeout'){
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Request Time out'); 
              } else {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Erreur inconnue');
              }
              $('.msgboxdebug.modal').modal({
                      closable  : true,
                      dimmerSettings: {opacity:0}
              }).modal('show');              
          }
    });


}