var ajaxForm = function( event, thiselement ) {
  
  event.preventDefault();
  var $this = thiselement;
  var idZoneMsg;
  var url;
  var type;
  var serializeForm;
  

    idZoneMsg = $this.attr('idZoneMsg');
  
    if ($this.attr('prevent')) 
    {
       //if(!confirm($this.attr('prevent'))) return false;
        MsgBoxPrevent($this.attr('prevent'),$this[0].id);
        return false;
    }
  
    // Ajoute les données du bouton submit dans la requete AJAX
    var submitbtn = $this.find("button[type=submit]:focus");
    if(submitbtn.length > 0) // Si un boutton submit est detecté avec des données
    {
      //if (submitbtn.attr('prevent')) {if(!confirm(submitbtn.attr('prevent'))) return false;} // Vérifie si un prevent est présent
      if (submitbtn.attr('prevent')) 
      {
        MsgBoxPrevent(submitbtn.attr('prevent'),submitbtn[0].id);
        return false;
      }
      serializeForm = $this.serialize() + '&' + encodeURI(submitbtn.attr('name')) + '=' + encodeURI(submitbtn.val()); // Serialize avec les éléments du bouton submit
    }
    else // Aucune donnée sur le bouton submit
    serializeForm = $this.serialize();
    url = $this.attr('action');
    type = $this.attr('method');

    // Modification de la requête AJAX si formulaire envoi de fichiers
    var contentType = "application/x-www-form-urlencoded; charset=UTF-8";
    var processData = true;
    if($this.attr('enctype') === 'multipart/form-data'){
        serializeForm = new FormData($this[0]); 
        contentType = false;
        processData = false;
    }

    $.ajax({
          url: url,
          type: type, 
          data: serializeForm, 
          dataType : 'json',
          contentType : contentType,
          processData : processData,
          headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          },
          beforeSend: function() { 
            
            // Vide les erreurs
            $("#" + idZoneMsg).hide();
            $("#" + idZoneMsg).find("p:first").empty();  
            $("#" + idZoneMsg).find("header").empty();  
            $("#" + idZoneMsg).removeClass('positive').removeClass('negative');

            // Active loader sur le bouton avec le focus
              $("button[type=submit]:focus",$this).addClass('loading');
              $("button[type=submit]:focus",$this).prop('disabled', true);

          },
          complete: function(){
            // désactive loader du bouton
                $("button[type=submit]",$this).removeClass('loading');
                $("button[type=submit]",$this).prop('disabled', false);
          },
          success: function(data) { 
              
              if ( data.debug ) console.log(data);

              // Gestion des erreurs
              if ( data.success == false )
              {
                  $("button[type=submit]",$this).removeClass('loading');
                  $("button[type=submit]",$this).prop('disabled', false);

                  // Paramétrage des erreurs
                  if ( data.display_mode_error == 1 ) // INNER
                  {
                    // Liste des erreurs
                    var errorlist = '<ul class="list">';
                    if ( data.errors.length > 0 )
                      data.errors.forEach(function(entry) {errorlist = errorlist + "<li>" + entry + "</li>";});
                    else
                      errorlist = errorlist + "<li>Erreur inconnue</li>";
                    errorlist = errorlist + "</ul>";

                    $("#" + idZoneMsg).find("p:first").html(errorlist);
                    $("#" + idZoneMsg).find("div:first").html("Erreur");
                    $("#" + idZoneMsg).addClass('negative');
                    $("#" + idZoneMsg).show();

                    // Scroll to top
                    $('html, body').animate({
                        scrollTop: $("#" + idZoneMsg).offset().top - 100
                    }, 1000);
                  }
                  else if ( data.display_mode_error == 0 ) // POPUP
                  {
                      MsgBoxKo(data.errors);
                  }

                  // CallFunction si besoin
                  if ( typeof data.call_js_function !== 'undefined' ) 
                  {
                    var fn = window[data.call_js_function];
                    if(typeof fn === 'function') {
                      fn(data.call_js_arguments);
                    }
                  }

                  return; // fin
              }
              else if ( data.success == true ) 
              {

                  // Reload de la page
                  if ( data.reload == true )
                  {
                      window.location.reload(true);
                      return;
                  }
                  // Redirection avec ou sans msgbox avant
                  else if ( isset(data.redirect_url) && data.redirect_url.trim() != '' ) 
                  {
                      if ( data.msg != "" ) // Si message de confirmation existant (afficher msgbox avant redirection)
                      {
                          MsgBoxOk(data.msg, data.redirect_url);
                          return;
                      }
                      else // Redirection immédiate
                      {

                        var current_url;
                        
                        if ( window.location.href.indexOf('?') > -1 )
                          current_url = document.location.pathname + '?' + window.location.href.split('?')[1].match(/(^[^#]*)/)[0];
                        else
                          current_url = document.location.pathname;

                        var final_url = data.redirect_url.match(/(^[^#]*)/)[0];
                        if ( current_url == final_url ) window.location.reload(true);
                        window.location = data.redirect_url;
                        return;
                      }
                  }
                  // Message de succès sans redirection
                  else if ( data.display_mode_success == 0 ) // POPUP
                  {
                     MsgBoxOk(data.msg);
                  }
                  else if ( data.display_mode_success == 1 ) // INNER
                  {
                        $("#" + idZoneMsg).find("p:first").html(data.msg);
                        $("#" + idZoneMsg).addClass('positive');
                        $("#" + idZoneMsg).show();

                        // Scroll to top
                        $('html, body').animate({
                          scrollTop: $("#" + idZoneMsg).offset().top - 100
                        }, 1000);                 
                  }
                 
                  // CallFunction si besoin
                  if ( typeof data.call_js_function !== 'undefined' ) 
                  {
                    var fn = window[data.call_js_function];
                    if(typeof fn === 'function') {
                      fn(data.call_js_arguments);
                    }
                  }

              }

              
                  
          },
          error:function(x,e) {

              if (x.status==0) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Aucune connexion Internet'); 
              } else if(x.status==404) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Adresse URL introuvable');
              } else if(x.status==500) {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Internel Server Error'); 
              } else if(e=='parsererror') {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Parsing JSON Request failed'); 
              } else if(e=='timeout'){
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Request Time out'); 
              } else {
                  $(".msgboxdebug.modal #msgbox_debug_title").text('Erreur inconnue');
              }
              $(".msgboxdebug.modal #msgbox_debug_content").html(x.responseText);
              $('.msgboxdebug.modal').modal({
                      closable  : true,
                      dimmerSettings: {opacity:0}
              }).modal('show');          
          }
    });
  
  

}