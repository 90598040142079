$(document).ready(function () {
    var cpt = 0;
    $("#hamburger-menu").click(function () {
        window.scrollTo(0, 0);
        if (cpt == 0) {
            var x = $("#hamburger-menu").offset();
            $("#container_menu_hamburger #menu-container #menu-wrapper #hamburger-menu").css({"top": x.top, "left": x.left});
            cpt = cpt + 1;
        } else {
            cpt = 0;
            $("#container_menu_hamburger #menu-container #menu-wrapper #hamburger-menu").css({"top": "initial", "left": "initial"});
        }
    });

});
