
function MsgBoxRedirect(redirect_on_close){

    if ( window.location.href.indexOf('?') > -1 ){
        var current_url = document.location.pathname + '?' + window.location.href.split('?')[1].match(/(^[^#]*)/)[0];
    }

    else{
        var current_url = document.location.pathname;
    }

    var final_url = redirect_on_close.match(/(^[^#]*)/)[0];
    if ( current_url == final_url ) window.location.reload(true);
    window.location = redirect_on_close;

}
function MsgBoxOk(msg, redirect) { MsgBox(1, msg, redirect); }
function MsgBoxKo(msg, redirect) { MsgBox(2, msg, redirect); }
function MsgBoxPrevent(msg, idlink) { MsgBox(3, msg, '', idlink ); }
function MsgBox(type, msg, redirect_on_close, idlink)
{

        // Si tableau d'erreurs
        if (msg instanceof Array)
        {
            if ( msg.length == 1 )
            {
                msg = msg[0];
            }
            else
            {
                var errorlist = '<ul class="list">';
                if ( msg.length > 0 ) msg.forEach(function(entry) {errorlist = errorlist + "<li>" + entry + "</li>";});
                msg = errorlist + "</ul>";
            }

        }

        $('.mini.msgbox.modal #msgbox_description').html(msg);

        // Initialise
        $('.mini.msgbox.modal #msgbox_icon').removeClass('fas fa-times').removeClass('red').removeClass('fas fa-check').removeClass('blue').removeClass('fas fa-question').removeClass('blue');
        $('.mini.msgbox.modal #msgbox_cancel').addClass('reveal').addClass('hidden');

        if ( type == 1 ) // MsgBox OK
        {
            $('.mini.msgbox.modal #msgbox_icon').addClass('fas fa-check').addClass('blue');
            $('.mini.msgbox.modal #msgbox_header').html('Confirmation');
        }
        else if ( type == 2 ) // MsgBox KO
        {
            $('.mini.msgbox.modal #msgbox_icon').addClass('fas fa-times').addClass('red');
            $('.mini.msgbox.modal #msgbox_header').html('Erreur');
        }
        else if ( type == 3 ) // Prevent
        {
            $('.mini.msgbox.modal #msgbox_icon').addClass('fas fa-question').addClass('blue');
            $('.mini.msgbox.modal #msgbox_header').html('Confirmation');
            $('.mini.msgbox.modal #msgbox_cancel').removeClass('reveal').removeClass('hidden');

        }

        $('.mini.msgbox.modal').modal({
            closable  : false,
            dimmerSettings: {opacity:0.1},
            onDeny    : function(){
              // TODO
               //return false;
            },
            onApprove : function() {

                if ( type == 3 )
                {
                    $('#' + idlink).attr('prevent',''); // Enleve le contenu de prevent pour que le prochain click permette de continuer la fonction
                    $('#' + idlink).focus();
                    $('#' + idlink).click(); // Simule le click sur le lien
                    return false;
                }

                if ( redirect_on_close !== undefined )
                {
                    MsgBoxRedirect(redirect_on_close);
                }

            }
        }).modal('show');


        $(document).keypress(function(e) {
            if(e.which == 13) {

                var modale_active = $('.mini.msgbox.modal.active');

                if(modale_active.length > 0){
                    modale_active.modal('hide');

                    if ( redirect_on_close !== undefined )
                    {
                        MsgBoxRedirect(redirect_on_close);
                    }
                }
            }
        });


};
